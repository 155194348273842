import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";
import { ProformaEntity } from "../../domain/entities/proforma/proforma_entity";

interface PendingPaymentsProps {
  proformas: ProformaEntity[];
}

const PendingPayments: React.FC<PendingPaymentsProps> = ({ proformas }) => {
  // Filtrar pagos pendientes (status === 0) y calcular el monto restante
  const pendingPayments = proformas.flatMap((proforma) =>
    proforma.payments
      .filter((payment) => payment.status === 0)
      .map((payment) => {
        const totalTransactionsAmount = payment.transactions.reduce(
          (acc, transaction) => acc + transaction.amount,
          0
        );
        const remainingAmount = payment.amount - totalTransactionsAmount;
        return {
          ...payment,
          remainingAmount,
        };
      })
  );

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px", // Cambiado a height fija para contenedor completo
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography
        variant="h6"
        sx={{ marginBottom: "16px", textAlign: "center" }}
      >
        Pagos Pendientes
      </Typography>

      <Box
        sx={{
          width: "100%",
          overflowY: "auto", // El scroll ahora está en el contenedor interno
          maxHeight: "400px", // Altura máxima interna para el scroll
        }}
      >
        {pendingPayments.length === 0 ? (
          <Typography variant="body2" color="textSecondary" align="center">
            No hay pagos pendientes.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {pendingPayments.map((payment, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    padding: "15px",
                    borderRadius: "7px",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">Pago ID: {payment.id}</Typography>
                  <Typography variant="body1">
                    Monto restante: ${payment.remainingAmount.toFixed(2)}
                  </Typography>
                  <Typography variant="body2">
                    Fecha de vencimiento: {payment.due_date}
                  </Typography>
                  <Typography variant="body2">
                    Fecha límite: {payment.deadline}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PendingPayments;
