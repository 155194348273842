import React, { useEffect } from "react";
import { Container, Grid, Box, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GetContainersEvent } from "../../events/get_containers_event";
import ContainerDisplay from "./ContainersDisplay";
import { ContainerFullDetailEntity } from '../../../domain/entities';
import ContainersNavBar from "./ContainersNavBar";
import StepsHistogram from '../steps_histogram';
import ContainerForm from "./Importations/ImportationForm";
import { Status } from '../../redux/slices/admin_container_slice';
import { AdminContainersModeEnum,setMode } from "../../redux/slices/admin_container_slice";


const ContainersAdmin: React.FC = () => {
    const { containers } = useAppSelector((state) => state.container);
    const { filter, mode } = useAppSelector((state) => state.admin_container); 
    const dispatch = useAppDispatch();
    
    const filteredContainers = filter.statuses.length > 0 
        ? containers.filter((container: ContainerFullDetailEntity) => 
            filter.statuses.includes(Number(container.containerMovements[container.containerMovements.length - 1].status.id)) 
          )
        : containers;

    const stepCounts = Array.from({ length: 12 }, (_, i) => ({
      step: Status[i].name,
      contenedores: filteredContainers.filter((container: ContainerFullDetailEntity) => {
        const lastMovement = container.containerMovements[container.containerMovements.length - 1]
        return Number(lastMovement.status.id) === i + 1
      }).length,
    }));

    console.log(containers)
    
    useEffect(() => {
        dispatch(GetContainersEvent());
    }, [dispatch]);

    useEffect(() => {
      dispatch(setMode(AdminContainersModeEnum.View));
  }, []);

    return (
        <Box>
          {mode===AdminContainersModeEnum.View && (
            <><ContainersNavBar title="Contenedores"/> 
            <Container>
                <Grid
                    container
                    sx={{ padding: '10px' }}
                    spacing={3}
                >
                    <Grid item xs={12}>
                      <Typography variant="h5" sx={{ marginTop: "30px",  fontWeight: '600' }}>
                        Estado de contenedores
                      </Typography>
                      <StepsHistogram stepData={stepCounts} />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {
                                filteredContainers.map((container: ContainerFullDetailEntity) => {
                                  return (
                                    <Grid item xs={12} sm={6} md={4} key={container.id}>
                                      <ContainerDisplay container={container} />
                                    </Grid>
                                  );
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            </>
            )
            }
            {mode === AdminContainersModeEnum.Create && 
                <Container>
                <ContainerForm />
                </Container>}
        </Box>
    );
};

export default ContainersAdmin;
