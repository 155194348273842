import React, { useState,useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Box, TextField, Button, Typography } from '@mui/material';
import { reset } from '../../../redux/slices/admin_container_slice';
import { CreateContainerEvent } from '../../../events/create_container_event';
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { AdminContainersModeEnum ,setMode,setSelectedContainer} from "../../../redux/slices/admin_container_slice";


const ContainerForm: React.FC = () => {
  const {mode}=useAppSelector((state)=>state.admin_container)
  const [capacity, setCapacity] = useState('');
  const [fixed_cost, setCost] = useState('');
  const [tax, setTax] = useState('');

  const { containers, status } = useAppSelector((state) => state.container);


  
  const dispatch = useAppDispatch();

  const handleVolver = () => {
    dispatch(reset());
  };
  const handleSubmitCrear = (e: React.FormEvent) => {
    e.preventDefault();
    const CapacidadANumero = parseFloat(capacity.replace(',', '.'));
    const CostoANumero = parseFloat(fixed_cost.replace(',', '.'));
    const TaxANumero =  parseFloat(tax.replace(',', '.'));

    if (isNaN(CapacidadANumero) || isNaN(CostoANumero)|| isNaN(TaxANumero)) {
        alert("El precio debe ser un número válido");
        return;
      }
    
     CreateContainerEvent(CapacidadANumero,CostoANumero,TaxANumero,dispatch,mode);
  };


  return (
    <Box sx={{ width: '80%', height: '80vh' }}>
    <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Capacidad"
                        variant="outlined"
                        value={capacity} // Asociar el estado
                        onChange={(e) => setCapacity(e.target.value)} 
                    />
                
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Tax"
                        variant="outlined"
                        value={tax} 
                        onChange={(e) => setTax(e.target.value)} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Costo Fijo"
                        variant="outlined"
                        value={fixed_cost} 
                        onChange={(e) => setCost(e.target.value)} 
                    />
                </Grid>

                {/* Fila con botones */}
                <Grid item xs={12} container spacing={2} justifyContent="center">
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleSubmitCrear}>Guardar</Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={handleVolver}>Cancelar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
  </Box>
  );
};

export default ContainerForm;
