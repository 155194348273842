import React from "react";
import { ProductEntity } from "../../domain/entities";
import { Card, Typography, Box } from "@mui/material";

interface ProductCardProps {
  product: ProductEntity;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <Card
      elevation={6}
      sx={{
        height: 300,
        aspectRatio: "4/5",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "16px",
        overflow: "hidden",
        position: "relative",
        transition: "transform 0.3s ease",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      {/* Image Box */}
      <Box
        sx={{
          height: "70%",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url(${product.imageUrl})`,
        }}
      />

      {/* Title and Price Box */}
      <Box
        sx={{
          padding: "10px",
          textAlign: "center",
          color: "#000",
        }}
      >
        {/* Fijar la altura del contenedor del título */}
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            color: "#1565C0",
            height: "40px",  // Altura fija para los títulos
            overflow: "hidden", // Evitar que títulos largos desajusten el diseño
            textOverflow: "ellipsis", // Añadir puntos suspensivos si es muy largo
            whiteSpace: "nowrap", // Evitar que el texto se divida en varias líneas
          }}
        >
          {product.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 500,
            color: "#424242",
          }}
        >
          Precio: {product.price} USD
        </Typography>
      </Box>
    </Card>
  );
};

export default ProductCard;


