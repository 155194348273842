import { combineReducers } from "redux";
import userSlice from "./slices/user_slice";
import layoutSlice from "./slices/layout_slice";
import containerSlice from "./slices/container_slice";
import registerSlice from "./slices/register_slice";
import productSlice from "./slices/product_slice";
import proformaSlice from "./slices/proforma_slice"; 
import admin_productSlice from "./slices/admin_products_slice";
import admin_containerSlice from "./slices/admin_container_slice";
import divisaSlice from "./slices/divisa_slice";
import companySlice from "./slices/company_slice";
import companyPaymentsSlice from "./slices/company_payments_slice";
import { configureStore } from "@reduxjs/toolkit";
import metricSlice from './slices/metric_slice';

const rootReducer = combineReducers({
  user: userSlice,
  divisa: divisaSlice,
  layout: layoutSlice,
  container: containerSlice,
  register: registerSlice,
  product: productSlice,
  proformas: proformaSlice, 
  admin_products: admin_productSlice,
  admin_container: admin_containerSlice,
  company: companySlice,

  company_payments: companyPaymentsSlice,

  metric: metricSlice,

});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
