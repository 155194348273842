import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { GetProformasEvent } from "../events/get_proformas_event";
import ProformaCarousel from "../components/import_carousel_dashboard";
import PendingPayments from "../components/pending_payments";
import MultiTransaction from "../components/jumbo_transaction";

const CustomerDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { proformas } = useAppSelector((state) => state.proformas);

  useEffect(() => {
    dispatch(GetProformasEvent());
  }, [dispatch]);

  // Filtrar proformas donde current_step != "Entregado"
  const proformasFiltradas = proformas.filter(
    (proforma) => proforma.current_step !== "Entregado"
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "100vh",
        position: "relative",
        paddingTop: "10px",
      }}
    >
      {/* Título */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          marginBottom: "30px",
          marginTop: "30px",
        }}
      >
        Importaciones Pendientes
      </Typography>

      {/* Componente de Carrusel */}
      <ProformaCarousel proformas={proformasFiltradas} />

      {/* Contenedor para los componentes MultiTransaction y PendingPayments */}
      <Box sx={{ width: '100%', marginTop: '30px', padding: '0 20px' }}>
        <Grid container spacing={2}>
          {/* Componente MultiTransaction a la izquierda */}
          <Grid item xs={12} md={6}>
            <MultiTransaction proformas={proformasFiltradas} />
          </Grid>

          {/* Componente PendingPayments a la derecha */}
          <Grid item xs={12} md={6}>
            <PendingPayments proformas={proformasFiltradas} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerDashboard;
