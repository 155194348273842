import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  LinearProgress,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";

import { GetProformasEvent } from "../events/get_proformas_event";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { ProformaEntity } from "../../domain/entities";
import PaymentCard from "../components/payment_card";
import ClarificationRequest from "../components/clarification_request";

const EstadoDeCuenta: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  //Con esto (useAppSelector) agarramos el status que nos interesa estudiar
  const { proformas }: { proformas: ProformaEntity[] } = useAppSelector(
    (state) => state.proformas
  );
  //Variables para setear el file, que es la imagen del comprobante

  //Obtenemos las proformas
  useEffect(() => {
    dispatch(GetProformasEvent());
  }, []);

  // Filtrar las proformas que coincidan con el id
  const proformasFiltradas = proformas.filter(
    (proforma) => proforma.id === id && proforma.payments
  );

  const totalAmountPaid = proformasFiltradas[0]?.payments.reduce(
    (sumPayments, payment) => {
      // Sumar todos los montos de las transacciones dentro de cada payment
      const totalTransactionsAmount = payment.transactions.reduce(
        (sumTransactions, transaction) => {
          return sumTransactions + transaction.amount;
        },
        0
      );

      // Sumar el total de las transacciones de este payment al total general
      return sumPayments + totalTransactionsAmount;
    },
    0
  );

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
        Estado de cuenta
      </Typography>
      {proformasFiltradas.length === 0 ? (
        <Typography variant="body1">No se encontraron proformas.</Typography>
      ) : (
        proformasFiltradas.map((proforma) => (
          <Box
            key={proforma.id}
            sx={{ display: "flex", flexDirection: "row", mb: 2 }}
          >
            <Box sx={{ flex: 1 }}>
              <img
                src={proforma.productImageUrl}
                alt="Producto"
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <Card
                sx={{
                  padding: "5px",
                  borderRadius: "30px",
                  borderColor: "#0A66A5",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  mt: 2,
                }}
              >
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid
                    item
                    xs
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#0A66A5",
                        lineHeight: "1",
                      }}
                    >
                      {proforma.productSize||"40x40"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#0A66A5",
                        lineHeight: "1",
                      }}
                    >
                      Centímetros
                    </Typography>
                  </Grid>
                  <div style={{ border: "1px solid #0A66A5" }}></div>
                  <Grid
                    item
                    xs
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#0A66A5",
                        lineHeight: "1",
                      }}
                    >
                      {proforma.productWeight||"40"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#0A66A5",
                        lineHeight: "1",
                      }}
                    >
                      Gramos
                    </Typography>
                  </Grid>
                  <div style={{ border: "1px solid #0A66A5" }}></div>
                  <Grid
                    item
                    xs
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#0A66A5",
                        lineHeight: "1",
                      }}
                    >
                      15% algodón
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#0A66A5",
                        lineHeight: "1",
                      }}
                    >
                      {proforma.productMaterials||"80% poliester"}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" component="div">
                  Total transferido
                </Typography>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ color: "primary.main" }}
                >
                  ${totalAmountPaid.toLocaleString("es-CL")}{" "}
                  <Typography
                    variant="h4"
                    component="span"
                    sx={{ color: "text.secondary" }}
                  >
                    / ${proforma.totalAmount.toLocaleString("es-CL")}
                  </Typography>
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(totalAmountPaid / proforma.totalAmount) * 100}
                  sx={{ mt: 1 }}
                />
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mt: 1, textAlign: "center" }}
                >
                  {((totalAmountPaid / proforma.totalAmount) * 100).toFixed(2)}%
                  abonado
                </Typography>
              </Box>
            </Box>
            <Box sx={{ flex: 2, pl: 15 }}>
              {/* Aquí usamos el PaymentCard para cada payment */}
              {proforma.payments &&
                proforma.payments.map((payment) => (
                  <PaymentCard key={payment.id} payment={payment} />
                ))}
              <ClarificationRequest proforma={proforma} />
            </Box>
          </Box>
        ))
      )}
    </Container>
  );
};

export default EstadoDeCuenta;

// const totalTransferred = proformasFiltradas.reduce((sum, proforma) => {
//   return (
//     sum +
//     (proforma.payments?.reduce((sum, payment) => sum + payment.amount, 0) ||
//       0)
//   );
// }, 0);

// Ejemplos mientras estoy manejando el back
// const proforma = {
//   id: 'proforma-001',
//   productName: 'Paño amarillo multiuso',
//   productImageUrl: AppImages.panoAmarillo,
//   totalAmount: 7800000,
//   payments: [
//     {
//       amount: 3900000,
//       dueDate: '27 de Julio de 2024',
//       uploadDate: '',
//       receptionDate: '',
//       transactionType: '',
//       currency: 'CLP',
//       equivalence: '',
//     },
//     {
//       amount: 0,
//       dueDate: '27 de Agosto de 2024',
//       uploadDate: '',
//       receptionDate: '',
//       transactionType: '',
//       currency: 'CLP',
//       equivalence: '',
//     }
//   ]
// };

// const totalTransferred = proforma.payments.reduce((sum, payment) => sum + payment.amount, 0);
// const totalAmount = proforma.totalAmount;

// const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//   const file = event.target.files?.[0];
//   if (file) {
//     // Aquí voy a recibir el archivo
//     console.log(file);
//   }
// };
