import React, { useState, Fragment } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { DivisasEnum } from '../../redux/slices/divisa_slice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setDivisa } from '../../redux/slices/divisa_slice';

export default function DivisasMenu() {
  const {divisa} = useAppSelector((state) => state.divisa);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCurrencyChange = (divisa: DivisasEnum) => {
    dispatch(setDivisa(divisa));
    handleMenuClose();
  };

  return (
    <Fragment>
      <Button
        aria-controls={open ? 'currency-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="small"
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        {divisa.divisa}
      </Button>
      <Menu
        id="currency-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleCurrencyChange(DivisasEnum.USD)}>
          {DivisasEnum.USD}
        </MenuItem>
        <MenuItem onClick={() => handleCurrencyChange(DivisasEnum.CLP)}>
          {DivisasEnum.CLP}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
