import React from "react";
import { PaymentEntity } from "../../domain/entities";
import { Card, Typography, Box } from "@mui/material";
import { format } from "date-fns";

interface TransactionListProps {
  payment: PaymentEntity;
}

const getStatusText = (status: number) => {
  switch (status) {
    case 1:
      return "Completed";
    case 2:
      return "Pending";
    case 3:
      return "Failed";
    default:
      return "Unknown";
  }
};

const TransactionList: React.FC<TransactionListProps> = ({ payment }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Transactions</Typography>
      {payment.transactions.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No transactions available.
        </Typography>
      ) : (
        payment.transactions.map((transaction) => (
          <Card
            key={transaction.id}
            elevation={2}
            sx={{
              padding: "10px",
              marginBottom: "8px",
              borderRadius: "8px",
            }}
          >
            <Typography variant="body2">
              Transaction ID: {transaction.id}
            </Typography>
            <Typography variant="body2">
              Amount: $ {transaction.amount} USD
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Status: {getStatusText(transaction.status)}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary">
              Created At: {format(new Date(transaction.created_at), "dd/MM/yyyy HH:mm")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Updated At: {format(new Date(transaction.updated_at), "dd/MM/yyyy HH:mm")}
            </Typography> */}
          </Card>
        ))
      )}
    </Box>
  );
};

export default TransactionList;
