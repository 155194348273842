import React, { Fragment,useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Button, Menu, MenuItem, Checkbox, Grid } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { StatusContainerEnum, setContainerFilter } from '../../redux/slices/admin_container_slice';
import AddIcon from '@mui/icons-material/Add';
import { AdminContainersModeEnum} from '../../redux/slices/admin_container_slice';
import { setMode } from '../../redux/slices/admin_container_slice';

const ContainersNavBar: React.FC<{ title: string }> = ({ title }) => {
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.admin_container);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddContainer = () =>{
    dispatch(setMode(AdminContainersModeEnum.Create))
}

const StatusToNumberMap: { [key in StatusContainerEnum]: number } = {
  [StatusContainerEnum.INIT]: 1,
  [StatusContainerEnum.OC_CONTANDEM]: 2,
  [StatusContainerEnum.OC_PROVEEDOR]: 3,
  [StatusContainerEnum.PAGO]: 4,
  [StatusContainerEnum.FABRICANDO]: 5,
  [StatusContainerEnum.FABRICADO]: 6,
  [StatusContainerEnum.PUERTO_CHINO]: 7,
  [StatusContainerEnum.TRANSITO_MARITIMO]: 8,
  [StatusContainerEnum.PUERTO_DESTINO]: 9,
  [StatusContainerEnum.INTERNACION]: 10,
  [StatusContainerEnum.TRANSITO_BODEGA]: 11,
  [StatusContainerEnum.ENTREGADO]: 12,
  [StatusContainerEnum.PETICION_DISENO]: 13,
  [StatusContainerEnum.DISENO_CHINA]: 14,
  [StatusContainerEnum.PROTOTIPO]: 15,
  [StatusContainerEnum.ACEPTACION_DISENO]: 16
};
// const StatusToNumberMap: { [key in StatusContainerEnum]: number } = {
//   [StatusContainerEnum.ENT]: 12,
//   [StatusContainerEnum.ETB]: 11,
//   [StatusContainerEnum.INT]: 10,
//   [StatusContainerEnum.EPD]: 9,
//   [StatusContainerEnum.ETM]: 8,
//   [StatusContainerEnum.EPC]: 7,
//   [StatusContainerEnum.FABO]: 6,
//   [StatusContainerEnum.FABD]: 5,
//   [StatusContainerEnum.PAG]: 4,
//   [StatusContainerEnum.OCP]: 3,
//   [StatusContainerEnum.OCC]: 2,
//   [StatusContainerEnum.INI]: 1,
// };

  
  const handleFilterChange = (status: StatusContainerEnum, checked: boolean) => {
    const statusNumber = StatusToNumberMap[status];
    const updatedStatuses = checked
      ? [...filter.statuses, statusNumber]
      : filter.statuses.filter((s) => s !== statusNumber);
    dispatch(setContainerFilter({ statuses: updatedStatuses }));
  };
  return (
    <Fragment>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ boxShadow: 'none' }}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleMenuOpen}>
            <FilterAltIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            {title || 'Contenedores'}
          </Typography>
          <Button variant="contained" onClick={handleAddContainer} startIcon={<AddIcon />}
              sx={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  '&:hover': {
                  backgroundColor: '#388e3c',
                  },
              }}>
                  Crear Container
          </Button>
        </Toolbar>
      </AppBar>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem>
          <Typography variant="h6">Estados</Typography>
        </MenuItem>
        <MenuItem>
          <Grid container direction="column">
            {Object.values(StatusContainerEnum).map((status) => (
              <Grid key={status}>
                <Checkbox
                  checked={filter.statuses.includes(StatusToNumberMap[status])}
                  onChange={(_, checked) => handleFilterChange(status, checked)}
                />
                {status} {/* Add a display name if needed */}
              </Grid>
            ))}
          </Grid>
        </MenuItem>
      </Menu>
    </Box>
    </Fragment>
  );
};

export default ContainersNavBar;
