import React from 'react';
import Badge, { badgeClasses } from '@mui/material/Badge';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Search from './Search';
import DivisasMenu from "./Divisa"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AppBar, Box, Toolbar, Typography, Stack } from '@mui/material';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';

export interface MenuButtonProps extends IconButtonProps {
  showBadge?: boolean;
}

function MenuButton({
  showBadge = false,
  ...props
}: MenuButtonProps) {
  return (
    <Badge
      color="error"
      variant="dot"
      invisible={!showBadge}
      sx={{ [`& .${badgeClasses.badge}`]: { right: 2, top: 2 } }}
    >
      <IconButton size="small" {...props} />
    </Badge>
  );
}

export default function Header() {


  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar position="static"  sx={{ backgroundColor: 'transparent' }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block',color: 'black' } }}
          >
            Contandem
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
            <Stack direction="row" spacing={2} sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Search />
              <DivisasMenu />
              <MenuButton showBadge aria-label="Open notifications">
                <NotificationsRoundedIcon />
              </MenuButton>
              <IconButton size="large" edge="end">
                <AccountCircleIcon />
              </IconButton>
            </Stack>
        </Toolbar>
      </AppBar>
  </Box>
  );
}