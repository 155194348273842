import React, { ChangeEvent, useState } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ContainerProductEntity } from "../../domain/entities";
import { CreateTransactionEvent } from "../events/create_transaction_event";
import { CreateProformaEvent } from "../events/create_proforma_event";
import { useAppDispatch } from "../hooks/hooks";
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";

const CLPFormatter = new Intl.NumberFormat("es-CL", {
  style: "currency",
  currency: "CLP",
});

const USDFromatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const CartPage: React.FC = () => {
  const { state } = useLocation();
  const { container, totalPrice, units, deliveryOption, address } = state;

  const dispatch = useAppDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [file, setFile] = useState<Blob | null>(null);
  const [transactionAmount, setTransactionAmount] = useState<number | string>(
    ""
  ); // Nuevo estado para el monto de la transacción

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const handleGenerateOrder = () => {
    console.log("Generating order...");
    if (!file) {
      alert("Please select a file.");
      return;
    }

    dispatch(setStatus(StatusLayoutEnum.Loading));

    CreateProformaEvent(
      container.containerProducts[0].id,
      units,
      totalPrice / units,
      deliveryOption === "envio",
      address === "" ? "N/A" : address
    ).then((proforma) => {
      console.log("Proforma generated successfully ", proforma);
      CreateTransactionEvent(
        file,
        proforma.payments[0].id,
        Number(transactionAmount) // Utilizamos el valor ingresado por el usuario
      )
        .then((transaction) => {
          console.log("Order generated successfully ", transaction);
          setIsSuccess(true);
        })
        .finally(() => {
          dispatch(setStatus(StatusLayoutEnum.Success));
        });
    });
  };

  const containerProduct = container
    .containerProducts[0] as ContainerProductEntity;

  console.log("CONTAINER PRODUCT: ", containerProduct);
  const orderedPaymentsByDueDate = [...container.containerPayments].sort(
    (a, b) => {
      const aSeconds = new Date(a.dueDate).getTime();
      const bSeconds = new Date(b.dueDate).getTime();
      return aSeconds - bSeconds; // No need to add +1 here
    }
  );

  return (
    <Container sx={{ padding: "20px" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          marginBottom: "30px",
        }}
      >
        Carrito de compras
      </Typography>
      {isSuccess ? (
        <Card
          sx={{
            padding: "30px",
            borderRadius: "50px",
            borderColor: "#0A66A5",
            borderWidth: "1px",
            borderStyle: "solid", // Add this line
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              color: "#0A66A5",
            }}
          >
            ¡Orden generada con éxito!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: "20px",
            }}
          >
            Tu orden de compra ha sido generada con éxito. Contandem confirmará
            la transferencia bancaria en un plazo de 1 día hábil.
          </Typography>
        </Card>
      ) : (
        <Card
          sx={{
            padding: "30px",
            borderRadius: "50px",
            borderColor: "#0A66A5",
            borderWidth: "1px",
            borderStyle: "solid", // Add this line
          }}
        >
          <Grid direction="column" container rowSpacing={3}>
            <Grid container item>
              <Grid container xs={6} item direction="column">
                <Container>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#0A66A5",
                    }}
                  >
                    {container.containerProducts[0].product.name}
                  </Typography>
                </Container>

                <Container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <img
                    src={container.containerProducts[0].product.imageUrl}
                    style={{
                      height: "200px",
                      aspectRatio: "1/1",
                    }}
                    alt="Random"
                  />
                </Container>
                <Container
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  <Card
                    sx={{
                      padding: "5px",
                      borderRadius: "30px",
                      borderColor: "#0A66A5",
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <Grid
                      container
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                           {container.containerProducts[0].product.size ||
                            "40x40"}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          Centímetros
                        </Typography>
                      </Grid>
                      <div style={{ border: "1px solid #0A66A5" }}></div>
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          {container.containerProducts[0].product.weight ||
                            "40x40"}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          Gramos
                        </Typography>
                      </Grid>
                      <div style={{ border: "1px solid #0A66A5" }}></div>
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#0A66A5",
                            lineHeight: "1",
                          }}
                        >
                          {container.containerProducts[0].product.materials ||
                            "40x40"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Container>
              </Grid>
              <Grid item xs={6}>
                <Container>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#0A66A5",
                    }}
                  >
                    Resumen de compra
                  </Typography>
                </Container>
                <Container
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <Grid item container>
                    <Grid item xs={6}>
                      <Typography variant="body1">Cantidad total:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {units / containerProduct.unitsPerPackage} Cajas -{" "}
                        {units} Unidades
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Precio:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {CLPFormatter.format(totalPrice / units)} CLP -{" "}
                        {USDFromatter.format(totalPrice / units / 950)} USD
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Monto total:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {CLPFormatter.format(totalPrice)} CLP -{" "}
                        {USDFromatter.format(totalPrice / 800)} USD
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {deliveryOption === "retiro"
                          ? "Retiro en Bodega"
                          : "Dirección de envío:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        {address ? address : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
                <Container
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "600",
                      color: "#0A66A5",
                    }}
                  >
                    Plan de pago
                  </Typography>
                </Container>
                <Container
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  {orderedPaymentsByDueDate.map((payment, index) => {
                    return (
                      <Container
                        key={index}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        <Typography fontWeight={"bold"}>
                          Pago {index + 1} - {payment.percentage * 100}%
                        </Typography>
                        <Container>
                          <Typography variant="body1">
                            Monto total:{" "}
                            {CLPFormatter.format(
                              Math.round(totalPrice * payment.percentage)
                            )}{" "}
                            CLP
                          </Typography>
                          <Typography variant="body1">
                            Fecha de Vencimiento:{" "}
                            {new Date(payment.dueDate).toLocaleDateString()}
                          </Typography>
                        </Container>
                      </Container>
                    );
                  })}
                </Container>
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Container>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "600",
                    color: "#0A66A5",
                  }}
                >
                  Condiciones del pago
                </Typography>
                <Container
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Typography variant="body1" textAlign={"justify"}>
                    Para asegurar tu compra, debes depositar el 20% del monto
                    total y adjuntar el comprobante de pago en esta orden de
                    compra. Contandem confirmará la transferencia bancaria en un
                    plazo de 1 día hábil. Puedes transferir a una de las
                    siguientes cuentas bancarias:
                  </Typography>
                </Container>
              </Container>
            </Grid>
            <Grid item>
              <Container>
                <Grid container item>
                  <Grid
                    container
                    item
                    xs={6}
                    direction={"column"}
                    alignContent={"center"}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      fontSize={20}
                    >
                      Transferencia en CLP
                    </Typography>
                    <Typography variant="body1">
                      Nombre: Contandem SpA
                      <br />
                      Banco Santander
                      <br />
                      Rut: 77.636.029-5
                      <br />
                      Cuenta Corriente
                      <br />
                      Número cuenta: 0-000-9445944-3
                      <br />
                      Correo: finanzas@contandem.cl
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    direction={"column"}
                    alignContent={"center"}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      fontSize={20}
                    >
                      Transferencia en USD
                    </Typography>
                    <Typography variant="body1">
                      Nombre: Contandem SpA
                      <br />
                      Banco Santander
                      <br />
                      Rut: 77.636.029-5
                      <br />
                      Número cuenta: 005104619152
                      <br />
                      Código SWIFT SANTANDER: BSCHCLRM
                      <br />
                      Dirección: Bandera 140, Santiago.
                      <br />
                      Correo: finanzas@contandem.cl
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            {/* Nuevo campo para ingresar el monto de la transacción */}
            <Grid container item direction="column">
              <Container>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", color: "#0A66A5" }}
                >
                  Monto de la transacción
                </Typography>
                <TextField
                  label="Monto de la transacción"
                  type="number"
                  fullWidth
                  value={transactionAmount}
                  onChange={(e) => setTransactionAmount(e.target.value)}
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                />
              </Container>
            </Grid>

            <Grid item container justifyContent={"end"} columnSpacing={4}>
              <Grid item>
                <form>
                  <input
                    accept="application/pdf"
                    id="contained-button-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color={"primary"}
                      component="span"
                    >
                      {file ? "Cambiar archivo" : "Seleccionar archivo"}
                    </Button>
                  </label>
                </form>
              </Grid>
              <Grid item>
                <Button
                  disabled={!file || !transactionAmount}
                  variant="contained"
                  color={"success"}
                  onClick={handleGenerateOrder}
                >
                  Generar orden
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </Container>
  );
};

export default CartPage;
