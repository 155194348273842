import apiRepository from "../../data/repository";
import CreateContainerMovementUseCase from "../../domain/usecases/create_movement_usecase";
import { ContainerMovementEntity } from '../../domain/entities';
//import {addMetric} from '../redux/slices/metric_slice';
import {reset} from '../redux/slices/admin_container_slice';
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";
import { AdminContainersModeEnum } from "../redux/slices/admin_container_slice";
import { setMode } from "../redux/slices/admin_container_slice";
export const CreateContainerMovementEvent = async (
            estimated_date: string,
            real_date: string,
            step: number,
            container: string,
            status:number,
            dispatch,
            mode
): Promise<ContainerMovementEntity> => {
    const usecase = new CreateContainerMovementUseCase(apiRepository);
    const result = await usecase.execute({
        estimated_date: estimated_date,
        real_date:real_date,
        step:step,
        container:container,
        status:status,
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const MovementEntity = result.right;
    if (!MovementEntity) {
        throw new Error("No se pudo crear el movimiento");
    }
    dispatch(setStatus(StatusLayoutEnum.Success));

    return MovementEntity;
}