import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProductEntity, CategoryProductEnum } from '../../../domain/entities/product/product_entity';

export enum AdminProductsModeEnum {
    View = 'VIEW',
    Create = 'CREATE',
    Edit = 'EDIT',
  }
  
  
export interface AdminProductsState {
    mode: AdminProductsModeEnum;
    selectedProduct: ProductEntity | null;
    error: string | null;

}

const initialState = {
    selectedProduct: null,
    error: null,
    mode: AdminProductsModeEnum.View
} as AdminProductsState;


const admin_productSlice = createSlice({
  name: 'admin_products',
  initialState,
  reducers: {
    setMode(state, action: PayloadAction<AdminProductsModeEnum>) {
        state.mode = action.payload;
      },
    setSelectedProduct(state, action: PayloadAction<ProductEntity | null>) {
        state.selectedProduct = action.payload;
        if (action.payload && state.selectedProduct) {
          state.mode = AdminProductsModeEnum.Edit;
        }
      },
    reset(state) {
        state.selectedProduct = null;
        state.error = null;
        state.mode = AdminProductsModeEnum.View;
    }
  },
});

export const { setMode, setSelectedProduct,reset } = admin_productSlice.actions;
export default admin_productSlice.reducer;
