// src/components/ContainerList.tsx
import React from 'react';
import { ContainerFullDetailEntity } from '../../../domain/entities';
import { Card, Container, Grid, LinearProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";

import { Status,AdminContainersModeEnum ,setMode,setSelectedContainer} from '../../redux/slices/admin_container_slice';

interface ContainerCardProps {
    container: ContainerFullDetailEntity;
}

const ContainerAdmin: React.FC<ContainerCardProps> = ({ container }) => {
    const [showProducts, setShowProducts] = React.useState(false);

    const lastMovement = container.containerMovements?.[container.containerMovements.length - 1];

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <Card
            elevation={3}
            sx={{
                padding: '20px',
                maxWidth: '400px',
                marginBottom: '40px',
                borderRadius: '50px',
                borderColor: '#0A66A5',
                borderWidth: '1px',
                borderStyle: 'solid',
            }}
        >
            <Grid container justifyContent={'center'}>
                <Grid container item xs={12} direction='row'>
                    <Container
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                          borderRadius: '8px',
                          color: '#0A66A5',
                        }}>
                        <Grid container item xs={12} direction='column' sx={{
                          justifyContent: 'center',
                        }}>
                            <Grid container item xs={12} direction='row' 
                                sx={{ 
                                  display: 'flex', 
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  borderRadius: '8px',
                                  transition: 'background-color 0.3s ease, color 0.3s ease',
                                  '&:hover': {
                                    cursor: 'pointer',
                                    backgroundColor: '#0A66A5',
                                    color: 'white',
                                  },
                                }}
                                onClick={() => {
                                  dispatch(setSelectedContainer(container));
                                  navigate('/admin/importations')
                                }}>
                                <FormatAlignJustifyIcon sx={{ marginRight: '10px', color: 'inherit' }} />
                                <Typography variant='h5' sx={{
                                  fontWeight: '600',
                                  color: 'inherit',
                                  borderRadius: '50px',
                                  padding: '10px',
                                }}>
                                    Contenedor
                                </Typography>
                            </Grid>
                            <Typography sx={{ alignSelf: 'center', overflow: 'auto' }}>
                              Creado el: {new Date(container.createdAt).toLocaleString()}
                            </Typography>
                        </Grid>
                    </Container>
                    
                    <Container>
                        <Card
                            sx={{
                                padding: '5px',
                                borderRadius: '30px',
                                borderColor: '#0A66A5',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                            }}
                        >
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        Status: {lastMovement?.status?.name || 'N/A'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Container>
                    <Container>
                      
                    </Container>
                </Grid>
                {container.containerProducts && container.containerProducts.length > 0 && (
                        <Grid container item xs={8} direction='column' sx={{
                          padding: '10px',
                          alignContent: 'center',
                        }}>
                            <Typography variant='h6' sx={{ cursor: 'pointer', color: '#0A66A5' }} onClick={() => setShowProducts(!showProducts)}>
                            {showProducts ? '▲' : '▼'} Productos
                            </Typography>
                            {showProducts && container.containerProducts.map((ContainerProduct, index) => (
                            <Grid container item key={ContainerProduct.product.id || index} sx={{ marginBottom: '10px', marginTop: '10px' }}>
                                <Grid item xs={1}>
                                <img 
                                    src={ContainerProduct.product.imageUrl} alt={ContainerProduct.product.name} 
                                    style={{ width: '100%', borderRadius: '5px' }} 
                                />
                                </Grid>
                                <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='body1' sx={{ color: '#0A66A5', fontWeight: '500' }}>
                                    {ContainerProduct.product.id} - {ContainerProduct.product.name}
                                </Typography>
                                </Grid>
                            </Grid>
                            ))}
                        </Grid>
                        )}

                        {container.units_total > 0 && (
                        <Grid container item xs={8} direction='column' sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid container item justifyContent={'center'}>
                            <Grid item xs={12}>
                                <LinearProgress
                                variant="determinate"
                                value={(container.units_sold / container.units_total) * 100}
                                color={'primary'}
                                sx={{ height: '8px', borderRadius: '10px' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2' sx={{ color: '#00000099', fontSize: '13px', fontWeight: 500, textAlign: 'center' }}>
                                {(container.units_sold / container.units_total) * 100}% del contenedor vendido - Quedan {container.units_total - container.units_sold} de {container.units_total} unidades
                                </Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        )}

            </Grid>
        </Card>
    );
};

export default ContainerAdmin;
