import apiRepository from "../../data/repository";
import CreateContainerUseCase from "../../domain/usecases/create_container_usecase";
import { ContainerEntity } from '../../domain/entities';
import {CreateContainerMovementEvent} from './create_container_movement_event'
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";
import { setMode,AdminContainersModeEnum } from "../redux/slices/admin_container_slice";
export const CreateContainerEvent = async (
    capacity:number,
    fixed_cost: number,
    tax: number,
    dispatch,
    mode
): Promise<ContainerEntity> => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new CreateContainerUseCase(apiRepository);

    const result = await usecase.execute({
        capacity:capacity,
        fixed_cost: fixed_cost,
        tax: tax
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const conainerEntitie = result.right;
    if (!conainerEntitie) {
        throw new Error("No se pudo crear el producto");
    }

    const infinityDate = new Date(3000, 11, 31).toISOString();
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    const endOfTodayISO = endOfToday.toISOString();
    dispatch(setMode(AdminContainersModeEnum.View))
    CreateContainerMovementEvent(endOfTodayISO,infinityDate,1,conainerEntitie.id,10,dispatch,mode);
    return conainerEntitie;
}