import React, { useState, ChangeEvent } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  MenuItem,
} from "@mui/material";
import { CreateTransactionEvent } from "../events/create_transaction_event";
import { useAppDispatch } from "../hooks/hooks";
import { addTransactionToPayment } from "../redux/slices/proforma_slice";
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";
import { ProformaEntity, PaymentEntity } from "../../domain/entities";

interface MultiTransactionProps {
  proformas: ProformaEntity[];
}

interface TransactionData {
  proformaId: string;
  paymentId: string;
  amount: number | string;
}

const MultiTransaction: React.FC<MultiTransactionProps> = ({ proformas }) => {
  const dispatch = useAppDispatch();
  const [selectedProforma, setSelectedProforma] = useState<string>("");
  const [transactions, setTransactions] = useState<TransactionData[]>([
    { proformaId: "", paymentId: "", amount: "" },
  ]);
  const [file, setFile] = useState<Blob | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const handleAddTransaction = () => {
    setTransactions([
      ...transactions,
      { proformaId: "", paymentId: "", amount: "" },
    ]);
  };

  const handleTransactionChange = (
    index: number,
    field: keyof TransactionData,
    value: string | number
  ) => {
    const newTransactions = [...transactions];
    newTransactions[index] = {
      ...newTransactions[index],
      [field]: value,
    };
    setTransactions(newTransactions);
  };

  const handleSubmit = () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }
  
    dispatch(setStatus(StatusLayoutEnum.Loading));
  
    let transactionPromises = transactions.map((transaction) => {
      if (transaction.paymentId && transaction.amount) {
        return CreateTransactionEvent(
          file,
          transaction.paymentId,
          Number(transaction.amount)
        )
          .then((newTransaction) => {
            console.log("Transaction created successfully", newTransaction);
            dispatch(
              addTransactionToPayment({
                paymentId: transaction.paymentId,
                transaction: newTransaction,
              })
            );
          });
      }
      return Promise.resolve(); // Si alguna transacción está incompleta, ignorarla
    });
  
    // Espera a que todas las promesas de transacciones terminen
    Promise.all(transactionPromises)
      .then(() => {
        console.log("All transactions processed successfully");
  
        // Limpiar el formulario después de procesar todas las transacciones
        setTransactions([{ proformaId: "", paymentId: "", amount: "" }]);
        setFile(null); // Limpiar el archivo seleccionado
  
        // Limpiar el input de archivo manualmente si es necesario
        const fileInput = document.getElementById("contained-button-file") as HTMLInputElement;
        if (fileInput) {
          fileInput.value = ""; // Limpiar el valor del input de archivo
        }
      })
      .finally(() => {
        dispatch(setStatus(StatusLayoutEnum.Success));
      });
  };

  const getPaymentsForProforma = (proformaId: string): PaymentEntity[] => {
    const proforma = proformas.find((p) => p.id === proformaId);
    return proforma ? proforma.payments : [];
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        height: "400px", // Definir una altura fija
        padding: "16px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: 1,
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: "16px" }}>
        Agregar Múltiples Transacciones
      </Typography>

      <Box
        sx={{
          width: "100%",
          maxHeight: "300px", // Altura máxima para el área de transacciones
          overflowY: "auto", // Activar scroll cuando sea necesario
        }}
      >
        {transactions.map((transaction, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={6}>
              <TextField
                select
                label="Seleccionar Proforma"
                value={transaction.proformaId}
                onChange={(e) => {
                  const proformaId = e.target.value;
                  handleTransactionChange(index, "proformaId", proformaId);
                  setSelectedProforma(proformaId);
                }}
                fullWidth
              >
                {proformas.map((proforma) => (
                  <MenuItem key={proforma.id} value={proforma.id}>
                    {proforma.productName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                label="Seleccionar Payment ID"
                value={transaction.paymentId}
                onChange={(e) =>
                  handleTransactionChange(index, "paymentId", e.target.value)
                }
                fullWidth
                disabled={!transaction.proformaId}
              >
                {getPaymentsForProforma(transaction.proformaId).map(
                  (payment) => (
                    <MenuItem key={payment.id} value={payment.id}>
                      {payment.id}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Monto de la Transacción"
                type="number"
                value={transaction.amount}
                onChange={(e) =>
                  handleTransactionChange(index, "amount", e.target.value)
                }
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
      </Box>

      <Button
        onClick={handleAddTransaction}
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px" }}
      >
        Agregar Transacción
      </Button>

      <Box sx={{ marginY: "16px" }}>
        <input
          accept="application/pdf"
          id="contained-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" color="primary" component="span">
            {file ? "Cambiar archivo" : "Seleccionar archivo"}
          </Button>
        </label>
      </Box>

      <Button
        onClick={handleSubmit}
        variant="contained"
        color="success"
        disabled={!file || !transactions.length}
      >
        Submit
      </Button>
    </Box>
  );
};

export default MultiTransaction;
