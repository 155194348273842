import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

interface CompanyDetailsProps {
  name: string;
  rut: string;
  address: string;
  phone: string;
  email: string;
  owner: string;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ name, rut, address, phone, email, owner }) => {
  return (
    <Paper elevation={3} sx={{ padding: '30px', marginBottom: '40px', width: '100%', maxWidth: '900px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}>
        {name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>RUT:</Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{rut}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Dirección:</Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{address}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Teléfono:</Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{phone}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Email:</Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Owner:</Typography>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{owner}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanyDetails;
