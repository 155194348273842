import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "./App.css";

import LoginPage from "./presentation/pages/login";
import ContainersPage from "./presentation/pages/containers";
import RegisterPage from "./presentation/pages/register";
import LoadingBackdrop from "./presentation/components/layout/loading_backdrop";
import SessionBackdrop from "./presentation/components/layout/session_redirect";
import Footer from "./presentation/components/layout/footer";
import { Header } from "./presentation/components/layout/header";
import HomePage from "./presentation/pages/home";
import ProductsPage from "./presentation/pages/products";
import HistorialComprasPage from "./presentation/pages/historialcompras";
import EstadoDeCuenta from "./presentation/pages/estadodecuenta";
import CartPage from "./presentation/pages/cart";
import AcceptUserPage from './presentation/pages/user_accept';
import AdminProducts from './presentation/pages/admin_products';
import AdminContainer from './presentation/pages/admin_containers';
import AdminClientsPage from './presentation/pages/admin_clients';
import AdminClientDetail from './presentation/pages/admin_client_detail';
import Dashboard from './presentation/pages/dashboard';
import AdminImportationsPage from './presentation/pages/admin_importations';
import CustomerDashboard from './presentation/pages/customer_dashboard';

const App = () => {

    return (
        <Fragment>
            <SnackbarProvider />
            <BrowserRouter>
                <LoadingBackdrop />
                <Routes>
                    <Route path="/" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <HomePage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/login" element={<Fragment><Header /><LoginPage /><Footer /></Fragment>} />
                    <Route path="/register" element={<Fragment><Header /><RegisterPage /><Footer /></Fragment>} />
                    <Route path="/accept-user/:user_id" element={
                        <Fragment><Header /><AcceptUserPage /><Footer /></Fragment>} />
                    <Route path="/containers" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <ContainersPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/products" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <ProductsPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/historialcompras" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <HistorialComprasPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/cart" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <CartPage />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/estadodecuenta/:id" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <EstadoDeCuenta />
                            <Footer />
                        </Fragment>
                    } />
                    <Route path="/admin" element={
                        <Fragment>
                            <Dashboard />
                        </Fragment>
                    } />
                   <Route path="/admin/products" element = {
                        <Fragment>
                            <AdminProducts />
                        </Fragment>
                    } />
                    <Route path="/admin/clients" element={
                        <Fragment>
                            <AdminClientsPage />
                        </Fragment>
                    }/>
                    <Route path="/admin_containers" element = {
                        <Fragment>
                            <AdminContainer/>
                        </Fragment>
                    }/>
                    <Route path="/admin/importations" element = {
                        <Fragment>
                            <AdminImportationsPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/admin_container" element = {
                        <Fragment>
                            <AdminImportationsPage/>
                        </Fragment>
                    }/>
                    <Route path="/admin/clients/:id" element={
                        <Fragment>
                            <AdminClientDetail />
                        </Fragment>
                    } />
                    <Route path="/customer-dashboard" element={
                        <Fragment>
                            <Header />
                            <SessionBackdrop />
                            <CustomerDashboard />
                            <Footer />
                        </Fragment>
                    } />
                </Routes>
            </BrowserRouter>
        </Fragment>
    );

};

export default App;

