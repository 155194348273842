import apiRepository from "../../data/repository";
import DeleteProductUseCase from "../../domain/usecases/delete_product_usecase";
import { useAppDispatch } from "../hooks/hooks";
import { GetProductsEvent } from "./get_products_event";
export const DeleteProductEvent = async (
    product_id:string
    ): Promise<void> => {
    const usecase = new DeleteProductUseCase(apiRepository);

    const result = await usecase.execute(
        product_id,
    );
}