import React from 'react';
import {
  Card, CardHeader, CardMedia, CardContent, CardActions, IconButton, Typography} from '@mui/material';
import { Favorite as FavoriteIcon, MoreVert as MoreVertIcon, Add as AddIcon } from '@mui/icons-material';
import { ProductEntity } from '../../../../domain/entities';
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { DivisasEnum } from '../../../redux/slices/divisa_slice';
import { setMode,AdminContainersModeEnum,setSelectedProduct } from '../../../redux/slices/admin_container_slice';
interface SelectProductCard {
    product: ProductEntity;
}

const AdminSelectProductCard: React.FC<SelectProductCard> = ({ product }) =>{
    const {divisa} = useAppSelector((state) => state.divisa);
    const dispatch = useAppDispatch();

    const handleSelectProduct = () => {
      dispatch(setSelectedProduct(product));

    };

  return (
    <Card sx={{ maxWidth: 345, height: 350 , display: 'flex', flexDirection: 'column'}}>
      <CardHeader
       sx={{ display: 'flex',height: '40%' ,width: "100%", }}
        title={
            <Typography
              variant="body1"
              sx={{
                maxHeight: '3em',
                width: "100%",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2, 
                flexGrow: 1,
                textAlign: 'center',
              }}
            >
              {product.name}
            </Typography>
          }

      />
      <CardMedia
        component="img"
        height="150px"
        image={product.imageUrl}
        alt= {product.name}
        sx={{
            objectFit: 'contain',
          }}
      />
    <CardContent sx={{ display: 'flex', alignItems: 'center',flexDirection: 'column', flexGrow: 1, height: '90px'}}>
      <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            textAlign: 'justify',
            flexGrow: 1,
          }}
        >
            {product.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ justifyContent: 'flex-end'}}>
        <IconButton aria-label="add to favorites" onClick={handleSelectProduct}>
          <AddIcon />
        </IconButton>
        { divisa.divisa === DivisasEnum.USD &&<Typography variant='subtitle1' sx={{  fontFamily: 'Congenial',color: 'Black', fontWeight: 'bold', flexGrow: 1, textAlign: 'center'}}>
                $ {product.price} USD
        </Typography>}
        { divisa.divisa === DivisasEnum.CLP &&<Typography variant='subtitle1' sx={{  fontFamily: 'Congenial',color: 'Black', fontWeight: 'bold', flexGrow: 1, textAlign: 'center'}}>
                $ {new Intl.NumberFormat('es-CL').format(product.price * divisa.value)} CLP
        </Typography>}
      </CardActions>
    </Card>
  );
}
export default AdminSelectProductCard;