import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  LinearProgress,
  Input,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import StepProgress from "./step_progress";
import { ProgressStepEntity } from "../../domain/entities/progress_step";

interface ImportCardProps {
  title: string;
  image: string;
  status: string;
  nextStep: string;
  progressSteps: ProgressStepEntity[];
  paymentProgress: number;
  amountPaid: number;
  totalAmount: number;
  debt: string;
  dueDate: string;
  id: string;
}

const ImportCard: React.FC<ImportCardProps> = ({
  title,
  image,
  status,
  nextStep,
  progressSteps,
  paymentProgress,
  amountPaid,
  totalAmount,
  debt,
  dueDate,
  id,
}) => {
  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
    }
  };

  const handleEstadoDeCuentaClick = () => {
    navigate(`/estadodecuenta/${id}`);
  };
  // console.log("PROGRESS STEPS: ", progressSteps)

  return (
    <Card sx={{ display: "flex", mb: 2, position: "relative" }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "40%" }}>
        <img
          src={image}
          alt={title}
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Box>
      <CardContent
        sx={{ display: "flex", flexDirection: "column", width: "60%" }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
          {status}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 6 }}>
          {nextStep}
        </Typography>
        <StepProgress steps={progressSteps} />
        <Box sx={{ my: 6 }}>
          <Typography variant="body1" color="text.primary" sx={{ mb: 1 }}>
            Abonado al costo del producto
          </Typography>
          <LinearProgress
            variant="determinate"
            value={paymentProgress}
            sx={{ mb: 1 }}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mb: 6 }}>
            {amountPaid.toFixed(0)} USD de {totalAmount} USD ({paymentProgress.toFixed(2)}
            %)
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Deuda: {debt} 

          </Typography>
          <Typography variant="body2" color="text.secondary">
            Vencimiento: {dueDate}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "auto" }}>
          <label htmlFor="upload-file">
            <Input
              id="upload-file"
              type="file"
              sx={{ display: "none" }}
              inputProps={{ accept: "*/*" }}
              onChange={handleFileChange}
            />
          </label>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEstadoDeCuentaClick}
          >
            Estado de cuenta
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImportCard;
