import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setCompanyOrder, OrderEnum } from '../../redux/slices/company_slice'; // Importa las acciones y enums
import SortIcon from '@mui/icons-material/Sort';

const ClientsNavBar: React.FC = () => {
    const dispatch = useAppDispatch();
    const { order } = useAppSelector((state) => state.company);

    const toggleOrder = () => {
        // Cambiar entre ascendente y descendente
        const newOrder = order === OrderEnum.ASC ? OrderEnum.DESC : OrderEnum.ASC;
        dispatch(setCompanyOrder(newOrder));
    };

    return (

        <AppBar position="static">
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                    Compañías
                </Typography>
                <Button 
                    onClick={toggleOrder} 
                    startIcon={<SortIcon />}
                    sx={{
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#388e3c',
                        },
                      }}>
                    Ordenado por RUT ({order === OrderEnum.ASC ? 'Asc' : 'Desc'})
                </Button>
            </Toolbar>
        </AppBar>

    );
};

export default ClientsNavBar;
