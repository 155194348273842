// src/presentation/pages/historialcompras.tsx
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { GetProformasEvent } from "../events/get_proformas_event";
import { Container, Typography } from "@mui/material";
import ImportCard from "../components/import_card";

const HistorialCompras: React.FC = () => {
  const dispatch = useAppDispatch();
  const { proformas } = useAppSelector((state) => state.proformas);

  useEffect(() => {
    dispatch(GetProformasEvent());
  }, []);
  // Función para calcular el amountPaid sumando las transactions
  const calculateAmountPaid = (proforma) => {
    return proforma.payments.reduce((sumPayments, payment) => {
      const totalTransactionsAmount = payment.transactions.reduce(
        (sumTransactions, transaction) => {
          return sumTransactions + transaction.amount;
        },
        0
      );
      return sumPayments + totalTransactionsAmount;
    }, 0);
  };

  // Función para formatear la fecha
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };
  return (
    <Container sx={{ padding: "20px" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          marginBottom: "30px",
        }}
      >
        Historial de compras
      </Typography>
      {proformas.map((proforma, index) => (
        <ImportCard
          key={index}
          id={proforma.id}
          title={proforma.productName}
          image={proforma.productImageUrl}
          status={proforma.current_step || "Desconocido"}
          nextStep={`Próximo paso: ${proforma.next_step || "Ninguno"}`}
          progressSteps={proforma.progressSteps || []}
          paymentProgress={
            Math.min(
              (calculateAmountPaid(proforma) / proforma.totalAmount) * 100,
              100
            ) || 0
          }
          amountPaid={calculateAmountPaid(proforma) || 0}
          totalAmount={proforma.totalAmount || 0}
          debt={`$${
            proforma.totalAmount - calculateAmountPaid(proforma) || 0
          } USD`}
          dueDate={formatDate(proforma.proforma_deadline) || ""}
        />
      ))}
    </Container>
  );
};

export default HistorialCompras;
