import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Header from '../components/admin/Header';
import SideMenu from '../components/admin/SideMenu';
import ProductsAdmin from '../components/admin/Products';


export default function AdminProductsPage(props: { disableCustomTheme?: boolean }) {
  return (
    <>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />

        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1
            // backgroundColor: theme.vars
            //   ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
            //   : alpha(theme.palette.background.default, 1),
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'stretch',
              mx: 0,
              pb: 10,
              mt: { xs: 8, md: 0 },
            }}
          >
            {/* <MainGrid /> */}
            <Header/>
            <ProductsAdmin/>
          </Stack>
        </Box>
      </Box>
      </>
  );
}