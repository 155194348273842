export interface CompanyEntity {
    id: string;
    name: string;
    address: string;
    phone: string;
    email: string;
    owner: string;
    rut: string;

}
export function mapJsonToCompanyEntity(json): CompanyEntity {
    return {
        id: json.id,
        name: json.name,
        address: json.address,
        phone: json.phone,
        email: json.email,
        owner: json.owner,
        rut: json.rut
    } as CompanyEntity;
}