import apiRepository from "../../data/repository";
import GetCompanyPaymentsUsecase from "../../domain/usecases/get_company_payments_usecase";
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";
import { setCompanyPayments } from "../redux/slices/company_payments_slice";

export const GetCompanyPaymentsEvent = (company_id: string) => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetCompanyPaymentsUsecase(apiRepository);

    const result = await usecase.execute({ company_id });

    result.fold(
        () => { },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));
            dispatch(setCompanyPayments([data])); // Si el slice necesita un array, lo envolvemos en uno.
        }
    );
};
