// src/components/ContainerList.tsx
import React from 'react';
import { ContainerFullDetailEntity } from '../../domain/entities';
import { Card, Container, Grid, LinearProgress, Typography } from '@mui/material';
import ContainerPriceTable from './container_price_table';
import ContainerQuantityTable from './container_quantity_table';
interface ContainerCardProps {
    container: ContainerFullDetailEntity;
}

const ContainerCard: React.FC<ContainerCardProps> = ({ container }) => {

    const container_product = container.containerProducts[0];
    const lenghtPrices = Math.ceil((
        container_product.maxQuantity - container_product.minQuantity) / container_product.interval
    );

    return (
        <Card
            elevation={3}
            sx={{
                padding: '30px',
                marginBottom: '40px',
                borderRadius: '50px',
                borderColor: '#0A66A5',
                borderWidth: '1px',
                borderStyle: 'solid', // Add this line
            }}
        >
            <Grid container>
                <Grid container item xs={4} direction='column'>
                    <Container>
                        <Typography variant='h5' sx={{
                            fontWeight: '600',
                            color: '#0A66A5',
                        }}>
                            {container.containerProducts[0].product.name}
                        </Typography>
                    </Container>

                    <Container
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '30px',
                        }}
                    >
                        <img
                            src={container.containerProducts[0].product.imageUrl}
                            style={{
                                height: '200px',
                                aspectRatio: '1/1',
                            }}
                            alt="Random"
                        />
                    </Container>
                    <Container
                        sx={{
                            marginTop: '30px',
                        }}
                    >
                        <Card
                            sx={{
                                padding: '5px',
                                borderRadius: '30px',
                                borderColor: '#0A66A5',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                            }}
                        >
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        {container_product.product.size||"40x40"}
                                    </Typography>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        Centímetros
                                    </Typography>
                                </Grid>
                                <div style={{ border: '1px solid #0A66A5' }}></div>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        {container_product.product.weight||"40"}
                                    </Typography>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        Gramos
                                    </Typography>
                                </Grid>
                                <div style={{ border: '1px solid #0A66A5' }}></div>
                                <Grid item xs sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Typography variant='body2' sx={{
                                        color: '#0A66A5',
                                        lineHeight: '1'
                                    }}>
                                        {container_product.product.materials||"80% poliester"}
                                    </Typography>
                                   
                                </Grid>
                            </Grid>
                        </Card>
                    </Container>
                </Grid>
                <Grid
                    container
                    item
                    xs={8}
                    direction='column'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Grid container item spacing={4}>
                        <Grid item xs={6}>
                            <ContainerPriceTable
                                container={container}
                                lenghtPrices={lenghtPrices}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ContainerQuantityTable
                                container={container}
                                lenghtPrices={lenghtPrices}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent={'center'}>
                        <Grid item xs={12}>
                            <LinearProgress
                                variant="determinate"
                                value={(container.units_sold / container.units_total) * 100}
                                color={'primary'}
                                sx={{
                                    height: '8px',
                                    borderRadius: '10px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{
                                color: '#00000099',
                                fontSize: '13px',
                                fontWeight: 500,
                                textAlign: 'center',
                            }}>
                                {(container.units_sold / container.units_total) * 100}% del contenedor vendido - Quedan {container.units_total - container.units_sold} de {container.units_total} unidades
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ContainerCard;
