import { HttpClientType } from "../../core/http_client";
import {
  ContainerEntity,
  mapJsonToContainerEntity,
  UserEntity,
  mapJsonToUserEntity,
  ProductEntity,
  mapJsonToProductEntity,
  ContainerFullDetailEntity,
  mapJsonToContainerFullDetailEntity,
  ProformaEntity,
  mapJsonToProformaEntity,
  TransactionEntity,
  mapJsonToTransactionEntity,
  ContainerProductEntity,
  mapJsonToContainerProductEntity,
  CompanyEntity,
  mapJsonToCompanyEntity,
  CompanyPaymentsEntity,
  mapJsonToCompanyPaymentsEntity,
  ClarificationRequestEntity,
  mapJsonToClarificationRequestEntity,
  MetricUnitEntity,
  mapJsonToMetricUnit,
  ContainerMovementEntity,
  mapJsonToContainerMovementEntity,
} from "../../domain/entities";

export interface ILoginUser {
  email: string;
  password: string;
}

export interface ICreateTransaction {
  id: string;
  file: Blob;
  payment_id: string;
  amount: number;
}

export interface ICreateProforma {
  id: string;
  product_container_id: string;
  quantity: number;
  price: number;
  dispatch: boolean;
  address: string;
}

export interface IRegisterUser {
  user_data: {
    first_name: string;
    last_name: string;
    email: string;
  };
  company_data: {
    phone: string;
    business_rut: string;
    business_name: string;
    business_category: string;
  };
}
export interface ICreateProduct {
  id: string;
  name: string;
  price: number;
  image_url: string;
  description: string;
  category: number;
  size: string;
  weight: string;
  materials: string;
}
export interface ICreateContainer {
  capacity: number;
  fixed_cost: number;
  tax: number;
}

export interface IPutContainer {
  id: string,
  capacity: number,
  fixed_cost: number,
  tax:number
}

export interface ICreateProductContainer {
  min_quantity: number;
  max_quantity: number;
  unit_base_price: number;
  increased_percent: number;
  interval: number;
  container: string;
  product: string;
  metric_unit: number;
  units_per_package: number;
}

//export interface IGetCompanyPayments {
//companyId: string;
//}

export interface ICreateMetric {
  name: string;
  description: string;
  square_meters: number;
}

export interface IClarificationRequest {
  id: string;
  proformaId: string;
  clientName: string;
  message: string;
  email: string;
  selectedTransaction: string;
}

export interface ICreateContainerMovement {
  estimated_date: string;
  real_date: string;
  step: number;
  container: string;
  status: number;
}

export interface IPutContainerMovement {
  id: number;
  estimated_date: string;
  real_date: string;
  step: number;
  container: string;
  status: number;
}

export interface ApiDataSource {
  // Containers
  getContainers(): Promise<ContainerEntity[]>;
  getContainersFullDetail(): Promise<ContainerFullDetailEntity[]>;
  createContainer(params: ICreateContainer): Promise<ContainerEntity>;

  putContainer(params:IPutContainer): Promise<ContainerFullDetailEntity>
  createProductContainer(params: ICreateProductContainer): Promise<ContainerProductEntity>;


  //
  loginUser(params: ILoginUser): Promise<string>;
  getProfile(): Promise<UserEntity>;
  registerUser(params: IRegisterUser): Promise<UserEntity>;
  deleteProduct(params: { product_id: string });
  // Products
  getProducts(): Promise<ProductEntity[]>;
  createProduct(params: ICreateProduct): Promise<ProductEntity>;
  putProduct(params: ICreateProduct): Promise<ProductEntity>;
  // Proforma
  getProformas(): Promise<ProformaEntity[]>;

  createTransaction(params: ICreateTransaction): Promise<TransactionEntity>;
  createProforma(params: ICreateProforma): Promise<ProformaEntity>;

  // Companies
  getCompanies(): Promise<CompanyEntity[]>;
  getCompanyPayments(company_id: string): Promise<CompanyPaymentsEntity>;

  sendClarificationRequest(
    params: IClarificationRequest
  ): Promise<ClarificationRequestEntity>;
  acceptUser(params: { user_id: string }): Promise<void>;

  getMetrics(): Promise<MetricUnitEntity[]>;
  createMetric(params: ICreateMetric): Promise<MetricUnitEntity>;

  createContainerMovement(
    params: ICreateContainerMovement
  ): Promise<ContainerMovementEntity>;
  putContainerMovement(
    params: IPutContainerMovement
  ): Promise<ContainerMovementEntity>;
}

export class ApiDataSourceImpl implements ApiDataSource {
  httpClient: HttpClientType;

  constructor(httpClient: HttpClientType) {
    this.httpClient = httpClient;
  }

  getContainers = async (): Promise<ContainerEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/`
    );
    return response.data.map((container) =>
      mapJsonToContainerEntity(container)
    );
  };

  getContainersFullDetail = async (): Promise<ContainerFullDetailEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/containers/full-detail/`
    );

    return response.data.map((container) =>
      mapJsonToContainerFullDetailEntity(container)
    );
  };

  createContainer = async (
    params: ICreateContainer
  ): Promise<ContainerEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/containers/`,
      {

        "capacity": params.capacity,
        "fixed_cost": params.fixed_cost,
        "tax":params.tax,
        "status": 1,
        "used_capacity":0,
      },

    );
    return mapJsonToContainerEntity(response.data);
  };


  putContainer= async (params: IPutContainer): Promise<ContainerFullDetailEntity> => {
    const response = await this.httpClient.put( `${process.env.REACT_APP_API_URL}api/container/${params.id}/`,
      {
        "id": params.id,
        "capacity": params.capacity,
        "fixed_cost": params.fixed_cost,
        "tax":params.tax,
      },
    );
    return mapJsonToContainerFullDetailEntity(response.data);
  }
  createProductContainer = async (params: ICreateProductContainer): Promise<ContainerProductEntity> =>{

    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/container_products/`,
      {
        min_quantity: params.min_quantity,
        max_quantity: params.max_quantity,
        unit_base_price: params.unit_base_price,
        increased_percent: params.increased_percent,
        interval: params.interval,
        units_per_package: params.units_per_package,
        container: params.container,
        product: params.product,
        metric_unit: 1,
      }
    );
    return mapJsonToContainerProductEntity(response.data);
  };

  getProducts = async (): Promise<ProductEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/products/`
    );
    return response.data.map((product) => mapJsonToProductEntity(product));
  };

  createProduct = async (params: ICreateProduct): Promise<ProductEntity> => {
    console.log("Price", params.id);
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/products/`,
      {
        id: params.id,
        name: params.name,
        price: params.price,
        image_url: params.image_url,
        description: params.description,
        category: params.category,
        size: params.size,
        weight: params.weight,
        materials: params.materials,
      }
    );
    return mapJsonToProductEntity(response.data);
  };

  putProduct = async (params: ICreateProduct): Promise<ProductEntity> => {
    const response = await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/product/${params.id}/`,
      {
        id: params.id,
        name: params.name,
        price: params.price,
        image_url: params.image_url,
        description: params.description,
        category: params.category,
        size: params.size,
        weight: params.weight,
        materials: params.materials,
      }
    );
    return mapJsonToProductEntity(response.data);
  };

  deleteProduct = async (params: { product_id: string }): Promise<void> => {
    await this.httpClient.delete(
      `${process.env.REACT_APP_API_URL}api/product/${params.product_id}/`
    );
  };

  loginUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<string> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/login/`,
      {
        email: email,
        password: password,
      },
      false
    );

    return response.data["token"] as string;
  };

  getProfile = async (): Promise<UserEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/profile/`
    );
    return mapJsonToUserEntity(response.data);
  };

  registerUser = async (params: IRegisterUser): Promise<UserEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/register/`,
      params
    );
    return mapJsonToUserEntity(response.data);
  };

  getProformas = async (): Promise<ProformaEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/user/proformas/`
    );
    return response.data.map((proforma) => mapJsonToProformaEntity(proforma));
  };

  createTransaction = async (
    params: ICreateTransaction
  ): Promise<TransactionEntity> => {
    console.log(
      "ENVIANDO transaction REQUEST. FILE: ",
      params.file,
      " Payment id:",
      params.payment_id,
      " params id:",
      params.id,
      " AMOUNT:",
      params.amount,
      "."
    );

    // Create a new FormData instance
    const formData = new FormData();
    formData.append("id", params.id);
    formData.append("file", params.file); // file is added as part of FormData
    formData.append("payment_id", params.payment_id);
    formData.append("amount", params.amount.toString()); // Ensure this is sent as a string

    // Adjust according to your httpClient setup
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/transactions/`,
      formData
      // Assuming options is passed correctly in your custom client
    );

    return mapJsonToTransactionEntity(response.data);
  };

  createProforma = async (params: ICreateProforma): Promise<ProformaEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/user/proformas/`,
      {
        id: params.id,
        product_container: params.product_container_id,
        quantity: params.quantity,
        price: params.price,
        dispatch: params.dispatch,
        address: params.address,
      }
    );

    return mapJsonToProformaEntity(response.data);
  };

  acceptUser = async (params: { user_id: string }): Promise<void> => {
    await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/accept-user/${params.user_id}/`,
      {}
    );
  };

  getCompanies = async (): Promise<CompanyEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/companies/`
    );
    return response.data.map((company) => mapJsonToCompanyEntity(company));
  };

  getCompanyPayments = async (
    company_id: string
  ): Promise<CompanyPaymentsEntity> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/companies/${company_id}/payments/`
    );

    return mapJsonToCompanyPaymentsEntity(response.data);
  };

  sendClarificationRequest = async (
    params: IClarificationRequest
  ): Promise<ClarificationRequestEntity> => {
    console.log("ENVIANDO CLARIFICATION REQUEST");
    console.log(
      "id: ",
      params.id,
      " proforma id: ",
      params.proformaId,
      " Client name: ",
      params.clientName,
      " message: ",
      params.message,
      " email: ",
      params.email,
      "transaction: ",
      params.selectedTransaction
    );
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/clarifications/`, // Asegúrate de que el endpoint sea correcto
      {
        //id: params.id,
        proforma_id: params.proformaId,
        client_name: params.clientName,
        message: params.message,
        client_email: params.email,
        selectedTransaction: params.selectedTransaction,
      }
    );

    // Mapea la respuesta al tipo ClarificationRequestEntity
    return mapJsonToClarificationRequestEntity(response.data);
  };

  getMetrics = async (): Promise<MetricUnitEntity[]> => {
    const response = await this.httpClient.get(
      `${process.env.REACT_APP_API_URL}api/metric_units/`
    );
    return response.data.map((metric) => mapJsonToMetricUnit(metric));
  };

  createMetric = async (params: ICreateMetric): Promise<MetricUnitEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/metric_units/`,
      {
        name: params.name,
        description: params.description,
        square_meters: params.square_meters,
      }
    );
    return mapJsonToMetricUnit(response.data);
  };

  createContainerMovement = async (
    params: ICreateContainerMovement
  ): Promise<ContainerMovementEntity> => {
    const response = await this.httpClient.post(
      `${process.env.REACT_APP_API_URL}api/container_movement/`,
      {
        estimated_date: params.estimated_date,
        real_date: params.real_date,
        step: params.step,
        container: params.container,

        status:params.status

      }
    );
    return mapJsonToContainerMovementEntity(response.data);
  };

  putContainerMovement = async (
    params: IPutContainerMovement
  ): Promise<ContainerMovementEntity> => {
    const response = await this.httpClient.put(
      `${process.env.REACT_APP_API_URL}api/container_movement/${params.id}/`,
      {
        estimated_date: params.estimated_date,
        real_date: params.real_date,
        step: params.step,
        container: params.container,
        status: params.status,
      }
    );
    return mapJsonToContainerMovementEntity(response.data);
  };
}
