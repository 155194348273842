import apiRepository from "../../data/repository";
import PutProductUseCase from "../../domain/usecases/put_product_usercase";
import { ProductEntity } from '../../domain/entities';
import { CategoryProductEnum } from '../../domain/entities';

function pasar_categoria_a_numero(category: CategoryProductEnum): number {
    const categoryValues = Object.values(CategoryProductEnum);
    const categoryIndex = Object.values(CategoryProductEnum).indexOf(category);

    if (categoryIndex === -1) {
        if (categoryIndex === -1) {
            return categoryValues.indexOf(CategoryProductEnum.OTHER);
        }
    }
    return categoryIndex;
}

export const PutProductEvent = async (
    id: string,
    name: string,
    price: number,
    image_url: string,
    description: string,
    category: CategoryProductEnum,
    size: string,
    weight: string,
    materials: string
): Promise<ProductEntity> => {
    const usecase = new PutProductUseCase(apiRepository);

    const result = await usecase.execute({
        id: id,
        name: name,
        price: price,
        image_url: image_url,
        description: description,
        category: pasar_categoria_a_numero(category),
        size: size,
        weight: weight,
        materials: materials
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const productEntity = result.right;
    if (!productEntity) {
        throw new Error("No se pudo crear el producto");
    }

    return productEntity;
}