import React, { useEffect } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { AdminContainersModeEnum } from "../../../redux/slices/admin_container_slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { GetContainersEvent } from '../../../events/get_containers_event';
import AddProductForm from './AddProduct';
import { ContainerFullDetailEntity } from "../../../../domain/entities";
import ImportationsNavBar from './importationsNavBar';
import ImportacionCard from "./ImportationCard";
import ContainerForm from "./ImportationForm";
import SelectProductList from "./SelectProductList";
import { useNavigate } from 'react-router-dom';

const ImportationAdmin: React.FC = () => {
    const { containers, status } = useAppSelector((state) => state.container);
    const {mode,selectedContainer,selectedProducto} = useAppSelector((state) => state.admin_container);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (mode === AdminContainersModeEnum.View) {
            navigate('/admin_containers')
        }
    }, [mode]);

    return (
        <Box>
                {/* Modo 'View' */}
                {mode === AdminContainersModeEnum.ViewContainer &&(
                    //  <><ImportationsNavBar /><Container>
                        <Box sx={{ padding: '10px' }} alignContent="center">
                            {/* {containers.map((importation: ContainerFullDetailEntity) => ( */}
                                <ImportacionCard container={selectedContainer} />
                            {/* ))} */}
                        </Box>
                    // </Container></>
                )}
                
                {mode === AdminContainersModeEnum.Create && 
                <Container>
                <ContainerForm />
                </Container>}
                
                {mode === AdminContainersModeEnum.Edit && (
                        <Box sx={{ padding: '10px' }} alignContent="center">
                            <ImportacionCard container={selectedContainer} />
                        </Box>
                )}

                {mode === AdminContainersModeEnum.SelectProduct && (
                    <SelectProductList/>
                )}
                {mode === AdminContainersModeEnum.AddProduct && (
                    <AddProductForm product={selectedProducto}/>
                )
                }
        </Box>
    
    );
};

export default ImportationAdmin;
