// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { ProformaEntity } from '../../../domain/entities/proforma/proforma_entity';

// export interface ProformaState {
//   proformas: ProformaEntity[];
// }

// const initialState: ProformaState = {
//   proformas: [],
// };

// const proformaSlice = createSlice({
//   name: 'proformas',
//   initialState,
//   reducers: {
//     setProformas(state, action: PayloadAction<ProformaEntity[]>) {
//       state.proformas = action.payload;
//     },
//   },
// });

// export const { setProformas } = proformaSlice.actions;
// export default proformaSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProformaEntity } from '../../../domain/entities/proforma/proforma_entity';
import { TransactionEntity } from '../../../domain/entities/transaction/transaction_entity'; // Asegúrate de tener esta entidad definida

export interface ProformaState {
  proformas: ProformaEntity[];
}

const initialState: ProformaState = {
  proformas: [],
};

const proformaSlice = createSlice({
  name: 'proformas',
  initialState,
  reducers: {
    setProformas(state, action: PayloadAction<ProformaEntity[]>) {
      state.proformas = action.payload;
    },

    // Nuevo reducer para añadir una transacción a un payment específico
    addTransactionToPayment(
      state,
      action: PayloadAction<{ paymentId: string; transaction: TransactionEntity }>
    ) {
      const { paymentId, transaction } = action.payload;
      
      // Iteramos sobre las proformas para encontrar el payment correspondiente
      state.proformas = state.proformas.map((proforma) => {
        return {
          ...proforma,
          payments: proforma.payments.map((payment) => {
            if (payment.id === paymentId) {
              // Añadimos la nueva transacción al array de transacciones del payment
              return {
                ...payment,
                transactions: [...payment.transactions, transaction],
              };
            }
            return payment;
          }),
        };
      });
    },
  },
});

export const { setProformas, addTransactionToPayment } = proformaSlice.actions;
export default proformaSlice.reducer;
