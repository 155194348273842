import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

interface StepsHistogramProps {
  stepData: { step: string; contenedores: number }[];
}

const StepsHistogram: React.FC<StepsHistogramProps> = ({ stepData }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={stepData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="step"
          angle={-30}
          textAnchor="end"
          interval={0} 
          tickLine={false}
          fontSize={12}
        >
        </XAxis>
        <YAxis>
          <Label value="Number of Containers" angle={-90} position="insideLeft" style={{ textAnchor: 'middle', fontSize: 14, fontWeight: 'bold' }} />
        </YAxis>
        <Tooltip formatter={(value: number) => [`Containers: ${value}`, '']} />
        <Bar dataKey="contenedores" fill="#0A66A5" barSize={30} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StepsHistogram;
