import { v4 as uuidv4 } from 'uuid';
import apiRepository from "../../data/repository";
import CreateTransactionUseCase from "../../domain/usecases/create_transaction_usecase";
import { TransactionEntity } from '../../domain/entities';

export const CreateTransactionEvent = async (
    file: Blob,
    payment_id: string,
    amount: number
): Promise<TransactionEntity> => {
    const usecase = new CreateTransactionUseCase(apiRepository);

    const result = await usecase.execute({
        id: uuidv4(),
        file: file,
        payment_id: payment_id,
        amount: amount
    });


    if (result.isLeft()) {
        throw new Error(result.left.message);
    }

    return result.right;
}