import React from 'react';
import {
  Card, CardHeader, Avatar, Typography, CardContent
} from '@mui/material';
import { red } from '@mui/material/colors';
import { CompanyEntity } from '../../domain/entities';
import { useNavigate } from 'react-router-dom';

interface CompanyCardProps {
  company: CompanyEntity;
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => {
  const navigate = useNavigate();

  // Lógica para manejar el clic en la tarjeta completa
  const handleCardClick = () => {
    navigate(`/admin/clients/${company.id}`);
  };

  return (
    <Card
      sx={{ maxWidth: 345, display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
      onClick={handleCardClick} // Aquí agregamos el handler de clic en la tarjeta
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }}>
            {company.name[0]}
          </Avatar>
        }
        title={company.name}
        subheader={company.rut}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Dirección: {company.address}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Teléfono: {company.phone}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: {company.email}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Owner: {company.owner}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
