import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SideMenu from '../components/admin/SideMenu';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import { GetCompanyPaymentsEvent } from '../events/get_company_payments_event';

// Importamos los componentes modularizados
import CompanyDetails from '../components/admin/ClientDetail';
import CompanyPayments from '../components/admin/ClientPayments';

const ClientDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Obtener el ID de la URL
  const dispatch = useAppDispatch();

  const { companyPayments } = useAppSelector((state) => state.company_payments); // Selector para obtener los pagos de la compañía
  const { companies } = useAppSelector((state) => state.company); // Selector para obtener los detalles de la compañía

  // Buscar la compañía correspondiente usando el ID
  const company = companies.find((company) => company.id === id);

  // Dispatch para obtener los pagos de la compañía al cargar la página
  React.useEffect(() => {
    if (id) {
      dispatch(GetCompanyPaymentsEvent(id)); // Llamar al evento para obtener los pagos de la compañía
    }
  }, [id, dispatch]);

  if (!company) {
    return <div>Compañía no encontrada</div>;
  }

  return (
    <>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              mx: 3,
              pb: 10,
              mt: { xs: 8, md: 5 }
            }}
          >
            {/* Pasamos los datos de la compañía al componente CompanyDetails */}
            <CompanyDetails
              name={company.name}
              rut={company.rut}
              address={company.address}
              phone={company.phone}
              email={company.email}
              owner={company.owner}
            />

            {/* Pasamos los pagos al componente CompanyPayments */}
            <CompanyPayments payments={companyPayments} />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ClientDetailPage;

