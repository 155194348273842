import React, { useState } from 'react';
import { Grid, Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { registerUserEvent } from '../events/get_register_event';
import { RegisterStatusEnum } from '../redux/slices/register_slice';
import AppImages from '../../theme/app_images';

const RegisterForm = () => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [business_rut, setBusinessRut] = useState('');
  const [business_name, setBusinessName] = useState('');
  const [business_category, setBusinessCategory] = useState('');
  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector((state) => state.register);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(registerUserEvent(first_name, last_name, email, phone, business_rut, business_name, business_category));
  };

  return (
    <Grid container>
      {/* Formulario */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            padding: 3
          }}
        >
          <Typography component="h1" variant="h5" gutterBottom>
            Regístrate en el formulario
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: '100%', // Mantiene el ancho total del formulario
              maxWidth: '400px', // Ajusta el máximo ancho de los campos
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="Nombre"
              name="first_name"
              autoComplete="fname"
              autoFocus
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Apellido"
              name="last_name"
              autoComplete="lname"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="phone"
              label="Teléfono"
              type="tel"
              id="phone"
              autoComplete="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="business_rut"
              label="RUT Empresa"
              id="business_rut"
              autoComplete="business-rut"
              value={business_rut}
              onChange={(e) => setBusinessRut(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="business_name"
              label="Nombre Empresa"
              id="business_name"
              autoComplete="business-name"
              value={business_name}
              onChange={(e) => setBusinessName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="business_category"
              label="Categoría Empresa"
              id="business_category"
              autoComplete="business-category"
              value={business_category}
              onChange={(e) => setBusinessCategory(e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              style={{
                width: '20%',
                borderRadius: '10px',
                height: '40px',
                backgroundColor: '#0A66A5',
                color: 'white',
                fontSize: '16px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={status === RegisterStatusEnum.Loading}
            >
              {status === RegisterStatusEnum.Loading ? <CircularProgress size={16} /> : 'Enviar'}
            </Button>
            </Box>
            {error && <Typography color="error">Hubo un error. Si este error continúa comunicate a hola@contandem.cl</Typography>}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          sx={{
            height: '100%',
            backgroundImage: `url(${AppImages.registerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RegisterForm;
