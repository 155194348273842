import apiRepository from "../../data/repository";
import GetCompaniesUsecase from "../../domain/usecases/get_companies_usecase";
import { setStatus, StatusLayoutEnum } from "../redux/slices/layout_slice";
import { setCompanies } from "../redux/slices/company_slice";

export const GetCompaniesEvent = () => async (dispatch) => {
    dispatch(setStatus(StatusLayoutEnum.Loading));
    const usecase = new GetCompaniesUsecase(apiRepository);

    const result = await usecase.execute();

    result.fold(
        () => { },
        (data) => {
            dispatch(setStatus(StatusLayoutEnum.Success));;
            dispatch(setCompanies(data));
        }
    );
}
