import apiRepository from "../../data/repository";
import PutProductUseCase from "../../domain/usecases/put_product_usercase";
import { ContainerMovementEntity } from '../../domain/entities';
import PutContainerMovementUseCase from "../../domain/usecases/put_container_movement_usecase";

export const PutContainerMovementEvent = async (
    id:number,
    estimated_date: string,
    real_date: string,
    step: number,
    container: string,
    status:number,
    dispatch,
    mode
): Promise<ContainerMovementEntity> => {
    const usecase = new PutContainerMovementUseCase(apiRepository);
    console.log(id);
    const result = await usecase.execute({
        id: id,
        estimated_date: estimated_date,
        real_date:real_date,
        step:step,
        container:container,
        status:status,
    });

    if (result.isLeft()) {
        const error = result.left;
        if (error) {
            throw new Error(error.message);
        } else {
            throw new Error("Error desconocido");
        }
    }

    const productEntity = result.right;
    if (!productEntity) {
        throw new Error("No se pudo crear el producto");
    }

    return productEntity;
}