import React, { useState,useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Box, TextField, Button, Dialog ,InputLabel,MenuItem,Select,DialogContent,DialogActions,DialogTitle,IconButton} from '@mui/material';
import { CategoryProductEnum } from '../../../domain/entities';
import { CreateProductEvent } from '../../events/create_product_event';
import { PutProductEvent} from '../../events/put_product_event';
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { AdminProductsModeEnum,reset} from "../../redux/slices/admin_products_slice";
import AddBoxIcon from '@mui/icons-material/AddBox';
const Proveedores = [
  { Id: 1, nombre: 'Tech Solutions S.A.' },
  { Id: 2, nombre: 'Global Supplies Inc.' },
  { Id: 3, nombre: 'Logística Integral S.L.' },
  { Id: 4, nombre: 'Importaciones Andina' },
  { Id: 5, nombre: 'Pacifico Trading Co.' }
];

const ProductForm: React.FC = () => {
  const {mode,selectedProduct} = useAppSelector((state) => state.admin_products);
  const [id, setId] = useState('');
  const [isImageValid, setIsImageValid] = useState(true);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState(CategoryProductEnum.BATHROOM);
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(''); 
  const [materials, setMaterials] = useState('');
  const [size, setSize] = useState('');
  const [weight,setWeight] = useState('');
  const [largo,setLargo] = useState('');
  const [alto,setAlto] = useState('');
  //const [selectedProveedor, setSelectedProveedor] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [newProveedor, setNewProveedor] = useState({
    nombre: '',
    nombreContacto: '',
    celular: '',
    email: '',
    direccion: '',
    ciudad: '',
    pais: ''
  });
  const [selectedProveedor, setSelectedProveedor] = useState(Proveedores[0].Id);
  const handleProveedorChange = (event) => {
    setSelectedProveedor(event.target.value); 
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    setNewProveedor({
      ...newProveedor,
      [e.target.name]: e.target.value
    });
  };

  const handleSaveProveedor = () => {
    console.log('Nuevo Proveedor:', newProveedor);
    handleCloseDialog();
  };

  useEffect(() => {
      if (mode===AdminProductsModeEnum.Edit){
        setId(selectedProduct.id);
        setName(selectedProduct.name);
        setCategory(selectedProduct.category);
        setPrice(selectedProduct.price.toString());
        setDescription(selectedProduct.description);
        setImageUrl(selectedProduct.imageUrl);
        setMaterials(selectedProduct.materials);
        setWeight(selectedProduct.weight);
        setSize(selectedProduct.size);

      } else if (mode === AdminProductsModeEnum.Create) {
        setId(uuidv4());
      }
  }, []);

  const dispatch = useAppDispatch();

  const handleImageError = () => {
    setIsImageValid(false); 
  };
  const handleImageLoad = () => {
    setIsImageValid(true); 
  }; 
  const handleImageUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.target.value);
    setIsImageValid(true);
  };
  const handleVolver = () => {
    dispatch(reset());
  };
  const handleSubmitCrear = (e: React.FormEvent) => {
    e.preventDefault();
    const PrecioANumero = parseFloat(price.replace(',', '.'));
    if (isNaN(PrecioANumero)) {
        alert("El precio debe ser un número válido");
        return;
      }
    CreateProductEvent(id, name, PrecioANumero, imageUrl, description, category,size,weight,materials);
    handleVolver()
  };

  const handleSubmitEditar = (e: React.FormEvent) => {
    e.preventDefault();
    const PrecioANumero = parseFloat(price.replace(',', '.'));
    if (isNaN(PrecioANumero)) {
        alert("El precio debe ser un número válido");
        return;
      }
    PutProductEvent(id, name, PrecioANumero, imageUrl, description, category,size,weight,materials);
    handleVolver()
  };

  return (
    <Box sx={{ width: '80%', height: '80vh' }}>
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ height: '15%' ,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '80%' }}>
            <TextField
              label="Nombre"
              required
              variant="standard"
              fullWidth
              value={name}
              autoComplete="off"
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
      </Grid>

      <Grid container item xs={12} sx={{ height: '85%' }}>
        <Grid item xs={8} sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Box sx={{ width: '90%', height: '100%'}}>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item   spacing={2} sx={{ height: '15%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  label="Materiales"
                  variant="outlined"
                  required
                  fullWidth
                  value={materials}
                  onChange={(e) => setMaterials(e.target.value)}
                  autoComplete="off"
                />
                <TextField
                  label="Peso"
                  variant="outlined"
                  required
                  fullWidth
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  autoComplete="off"
                  sx={{ marginLeft: '16px'}}
                />
              </Grid>
              <Grid item spacing={2} sx={{ height: '15%',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  label="Ancho"
                  variant="outlined"
                  required
                  fullWidth
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                  autoComplete="off"
                />
                <TextField
                  label="Largo"
                  variant="outlined"
                  required
                  fullWidth
                  value={largo}
                  onChange={(e) => setLargo(e.target.value)}
                  autoComplete="off"
                  sx={{ marginLeft: '16px'}}
                />
                                <TextField
                  label="Alto"
                  variant="outlined"
                  required
                  fullWidth
                  value={alto}
                  onChange={(e) => setAlto(e.target.value)}
                  autoComplete="off"
                  sx={{ marginLeft: '16px'}}
                />
              </Grid>
              <Grid item sx={{ height: '20%'}}>
              <Grid item xs={12} sx={{ height: '20%'}}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                  <Grid item xs={8} sx={{ height: '100%' }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Categoría"
                      value={category}
                      onChange={(e) => setCategory(e.target.value as CategoryProductEnum)}
                      select
                      SelectProps={{
                        native: true,
                      }}
                    >
                      {Object.values(CategoryProductEnum).map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  
                  <Grid item xs={4} sx={{ height: '100%' }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      label="Precio (USD)" 
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }} 
                    />
                  </Grid>
                </Grid>
              </Grid>

              </Grid>
              <Grid item sx={{ height: '50%'}}>
                <TextField
                  label="Descripción"
                  variant="outlined"
                  required
                  fullWidth
                  multiline
                  autoComplete="off"
                  value={description}
                  rows={7}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={4} sx={{ height: '100%' }}>
          <Grid container direction="column" sx={{ height: '100%' }}>
            <Grid item sx={{ height: '50%'}}>
              <Box sx={{alignContent:'center',display: 'flex',alignItems:'center',height: '100%',Width: '100%'}}>
              <img
                  src={isImageValid && imageUrl ? imageUrl : 'https://cdn.icon-icons.com/icons2/3001/PNG/512/default_filetype_file_empty_document_icon_187718.png'}
                  onError={handleImageError}
                  onLoad={handleImageLoad}
                  alt="Cargada"
                  style={{ maxWidth: '100%', maxHeight: '100%',marginLeft:'10%', objectFit: 'contain', borderRadius: '8px', padding: '10px' }}
                />
              </Box>
            </Grid>
            <Grid item sx={{ height: '15%'}}>
              <TextField
                label="URL de Imagen"
                variant="outlined"
                required
                fullWidth
                autoComplete="off"
                value={imageUrl}
                onChange={handleImageUrlChange}
              />
            </Grid>
            <Grid item sx={{ height: '15%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', width: '80%' }}>
                <TextField
                        margin="normal"
                        required
                        style={{ width: '100%' }}
                        label="Proveedor"
                        select
                        value={selectedProveedor}
                        onChange={handleProveedorChange}
                        SelectProps={{
                            native: true,
                        }}
                        >
                            {Proveedores.map((proveedor) => (

                    <option key={proveedor.Id} value={proveedor.Id}>
                    {proveedor.nombre}
                    </option>
                  ))}

                </TextField>
                </Box>
                <IconButton color="primary"  onClick={handleOpenDialog}  style={{ marginLeft: '10px' }}><AddBoxIcon />
                </IconButton>

              {/* Dialog para crear un nuevo proveedor */}
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Crear Nuevo Proveedor</DialogTitle>
                <DialogContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mt: 2,
                    width: '500px' // El Box ocupa el 100% del ancho
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      width: '80%', // Establecer el ancho de los TextField al 80%
                      margin: '0 auto' // Centrar el contenido dentro del Box principal
                    }}
                  >
                    <TextField
                      label="Nombre del Proveedor"
                      name="nombre"
                      value={newProveedor.nombre}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    <TextField
                      label="Nombre de Contacto"
                      name="nombreContacto"
                      value={newProveedor.nombreContacto}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    <TextField
                      label="Celular"
                      name="celular"
                      value={newProveedor.celular}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    <TextField
                      label="Email"
                      name="email"
                      value={newProveedor.email}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    <TextField
                      label="Dirección"
                      name="direccion"
                      value={newProveedor.direccion}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    <TextField
                      label="Ciudad"
                      name="ciudad"
                      value={newProveedor.ciudad}
                      onChange={handleInputChange}
                      fullWidth
                    />
                    <TextField
                      label="País"
                      name="pais"
                      value={newProveedor.pais}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </Box>
                </Box>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="secondary">
                    Cancelar
                  </Button>
                  <Button onClick={handleSaveProveedor} color="primary">
                    Guardar
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid item sx={{ height: '10%'}}>
              <Grid container spacing={2} sx={{alignItems:'center'}}>
                <Grid item xs={6}>
                  { mode===AdminProductsModeEnum.Create && <Button variant="contained" onClick={handleSubmitCrear} fullWidth>
                    Crear
                  </Button>}
                  { mode===AdminProductsModeEnum.Edit && <Button variant="contained" onClick={handleSubmitEditar} fullWidth>
                    Guardar
                  </Button>}
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={handleVolver}fullWidth>
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
  );
};

export default ProductForm;
