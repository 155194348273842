import { v4 as uuidv4 } from "uuid";
import apiRepository from "../../data/repository";
import SendClarificationUseCase from "../../domain/usecases/send_clarification_usecase";
import { ClarificationRequestEntity } from "../../domain/entities";

export const SendClarificationEvent = async (
  proformaId: string,
  clientName: string,
  message: string,
  email: string,
  selectedTransaction: string
): Promise<ClarificationRequestEntity> => {
  const usecase = new SendClarificationUseCase(apiRepository);

  const result = await usecase.execute({
    id: uuidv4(),
    proformaId: proformaId,
    clientName: clientName,
    message: message,
    email: email,
    selectedTransaction: selectedTransaction,
  });

  // Verificar si es un error (isLeft)
  if (result.isLeft()) {
    throw new Error(result.left?.message || "Error desconocido");
  }

  // Verificar si right es nulo antes de retornarlo
  if (!result.right) {
    throw new Error("Error: La solicitud no devolvió un resultado válido.");
  }

  return result.right;
};
