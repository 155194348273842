import React, { useEffect } from "react";
import { Container, Grid, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { GetCompaniesEvent } from "../../events/get_companies_event";
import CompanyCard from "../../components/company_card";
import { CompanyEntity } from "../../../domain/entities";
import ClientsNavBar from "./ClientsNavBar";
import { sortCompaniesByRut } from "../../redux/slices/company_slice";  // Asegúrate de importar la función de orden

const ClientsAdmin: React.FC = () => {
    const { companies, order } = useAppSelector((state) => state.company); // Añadimos el orden del estado
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(GetCompaniesEvent());
    }, [dispatch]);

    // Ordenar las compañías por RUT según el estado de `order`
    const sortedCompanies = sortCompaniesByRut(companies, order);  // Ya debería funcionar correctamente

    return (
        <Box>
            <ClientsNavBar /> {/* Aquí estará el navbar para ordenar */}
            <Container>
                <Grid container sx={{ padding: '10px' }} spacing={3}>
                    <Grid item container xs={12} direction={'column'}>
                        <Grid container spacing={4}>
                            {sortedCompanies.map((company: CompanyEntity) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={company.id}>
                                    <CompanyCard company={company} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ClientsAdmin;
