import { v4 as uuidv4 } from "uuid";
import apiRepository from "../../data/repository";
import CreateProformaUseCase from "../../domain/usecases/create_proforma_usecase";
import { ProformaEntity } from "../../domain/entities";

export const CreateProformaEvent = async (
  container_product_id: string,
  quantity: number,
  price: number,
  dispatch: boolean,
  address: string,
): Promise<ProformaEntity> => {
  const usecase = new CreateProformaUseCase(apiRepository);

  const result = await usecase.execute({
    id: uuidv4(),
    product_container_id: container_product_id,
    quantity: quantity,
    price: price,
    dispatch: dispatch,
    address: address,
  });

  if (result.isLeft()) {
    throw new Error(result.left.message);
  }

  return result.right;
};
